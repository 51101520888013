import classes from './Footer.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import SocialMedia from '../socialMedia/SocialMedia';

/**
 * Dynamic Footer
 *
 * @param attributes
 * @returns {JSX.Element|null}
 * @constructor
 */
const Footer = ({ attributes }) => {
    /**
     * return null needed for react render func
     */
    if (!attributes) return null;

    // FOR LOCAL CONTENT
    let { footer_links } = attributes;
    const logo = attributes.footer_logo;
    // FOR LOCAL CONTENT

    return (
        <div className={classes.oFooter}>
            <div className={`${classes.oContainer} container`}>
                <div className={`${classes.oRow} row`}>
                    <div className={classes.mFooterLeft}>
                        <figure className={`${classes.mLogo}`}>
                            <Link href="/home">
                                <a className={classes.mLogoLink}>
                                    <Image
                                        src={logo?.data?.attributes?.url}
                                        alt={`logo`}
                                        width={`400`}
                                        height={`528`}
                                        layout="responsive"
                                        className={`${classes.aImage}`}
                                    />
                                </a>
                            </Link>
                        </figure>
                    </div>
                    <div className={classes.mFooterCenter}>
                        <ul className={classes.mFooterMenu}>
                            {footer_links.map((link) => {
                                if (link.href) {
                                    return (
                                        <li key={link.id} className={classes.mLinkItem}>
                                            {link.isAnchor ? (
                                                <a
                                                    className={`fnt12f`}
                                                    id={link.id}
                                                    href={link.href}
                                                >
                                                    {link.label}
                                                </a>
                                            ) : (
                                                <Link href={link.href}>
                                                    <a
                                                        className={`fnt12f`}
                                                        rel="noopener noreferrer"
                                                        id={link.id}
                                                    >
                                                        {link.label}
                                                    </a>
                                                </Link>
                                            )}
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                    <div className={classes.mFooterRight}>
                        <SocialMedia attributes={attributes} themeName="themeDark" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
