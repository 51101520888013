import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { DefaultLayout as Layout } from '../components/templates';
import PageTransitions from '../components/templates/pageTransitions/PageTransitionSwipe';

import '../../assets/scss/.base/_bootstrap-reboot.min.css';
import '../../assets/scss/.base/_bootstrap-grid.min.css';
import '../../assets/scss/.base/slick.css';
import '../styles/globals.scss';
import '../styles/toast.scss';

/**
 * Component wrapper for application
 *
 * @param Component
 * @param pageProps
 * @returns {JSX.Element}
 * @constructor
 */
const App = ({ Component, pageProps }) => {
    const router = useRouter();
    const [routingPageOffset, setRoutingPageOffset] = useState(0);

    useEffect(() => {
        const pageChange = () => {
            setRoutingPageOffset(window.scrollY);
        };
        router.events.on('routeChangeStart', pageChange);
    }, [router.events]);

    return (
        <Layout {...pageProps.data}>
            <PageTransitions route={router.asPath} routingPageOffset={routingPageOffset}>
                <ToastContainer />
                <Component {...pageProps} />
            </PageTransitions>
        </Layout>
    );
};

export default App;
