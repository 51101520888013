import styled from 'styled-components';
import classes from './PageTransitions.module.scss';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const MainComponent = styled.div`
    &.page-enter-active {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
    }

    &.page-exit {
        ~ .wipe,
        + .wipe {
            transform: translateY(-100%);
            opacity: 0;
        }
    }
    &.page-exit-active {
        opacity: 0;
        ~ .wipe,
        + .wipe {
            transform: translateY(0);
            transition: 600ms ease;
            opacity: 1;
        }

        main {
            transform: translateY(-${(props) => props.routingPageOffset}px);
        }
    }
    &.page-enter-done {
        ~ .wipe,
        + .wipe {
            transform: translateY(100%);
            transition: 600ms ease;
            opacity: 0;
        }
    }
`;
const Wipe = styled.div``;
const PageTransitions = ({ children, route, routingPageOffset }) => {
    return (
        <>
            <TransitionGroup component={null}>
                <CSSTransition key={route} classNames="page" timeout={600}>
                    <MainComponent
                        className={`${classes.oComponent} oComponent`}
                        routingPageOffset={routingPageOffset}
                    >
                        {children}
                    </MainComponent>
                </CSSTransition>
                <Wipe className={`${classes.mWipe} wipe`} />
            </TransitionGroup>
        </>
    );
};

export default PageTransitions;
