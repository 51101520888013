import Image from 'next/image';
import Link from 'next/link';
import classes from './SocialMedia.module.scss';

const SocialMedia = ({ attributes, themeName }) => {
    let { social_links } = attributes;
    return (
        <ul className={`${classes.mSocialMedia} ${classes[`${themeName}`]}`}>
            {social_links?.map((social) => {
                if (social.href) {
                    return (
                        <li key={social.id} className={`${classes.mSocialItem} mLinkItem`}>
                            <Link href={social.href}>
                                <a
                                    target="_blank"
                                    className={classes.mLink}
                                    aria-label={social.name}
                                    rel="noopener noreferrer"
                                >
                                    {themeName === 'themeDark' ? (
                                        <Image
                                            src={social.temp_image}
                                            alt={social.name}
                                            width={`30`}
                                            height={`30`}
                                            layout="fixed"
                                            className={`${classes.aImage}`}
                                        />
                                    ) : (
                                        <Image
                                            src={social.temp_image_dark}
                                            alt={social.name}
                                            width={`24`}
                                            height={`24`}
                                            layout="fixed"
                                            className={`${classes.aImage}`}
                                        />
                                    )}
                                </a>
                            </Link>
                        </li>
                    );
                }
            })}
        </ul>
    );
};

export default SocialMedia;
