import { useState, useEffect, useRef } from 'react';
import classes from './MenuItem.module.scss';
import Link from 'next/link';
import { isMobile } from 'react-device-detect';
import Dropdown from '../dropDown/Dropdown';

const MenuItem = ({ handleToggle, item, router, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 991 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 991 && setDropdown(false);
    };

    const isActive = (path) => {
        if (router.pathname === path.href) return 'isActive';
        else if (
            (router.pathname === '/support' || router.pathname === '/faqs') &&
            path.label === 'Help'
        )
            return 'isActive';
        else if (
            (router.pathname === '/about-us' || router.pathname === '/careers') &&
            path.label === 'About us'
        )
            return 'isActive';
        else '';
    };

    const anchor = item.isAnchor;

    return (
        <span
            className={`${classes.mNavItem} ${classes[`${isActive(item)}`]} mNavItem`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            {item.dropdown_option ? (
                <>
                    <button
                        className={`${classes.aLink} ${classes.hasDropdown} aLink fnt16rs`}
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {item.label}
                        {depthLevel > 0 ? (
                            <span className={`${classes.aCarret} ${classes.toRight}`} />
                        ) : (
                            <span className={classes.aCarret} />
                        )}
                    </button>
                    <Dropdown
                        submenu={item.dropdown_option}
                        router={router}
                        dropdown={dropdown}
                        depthLevel={depthLevel}
                        handleToggle={handleToggle}
                    />
                </>
            ) : (
                <>
                    {isMobile ? (
                        anchor ? (
                            <a
                                className={`${classes.aLink} aLink fnt16rs`}
                                href={item.href}
                                onClick={handleToggle}
                            >
                                {item.label}
                            </a>
                        ) : (
                            <Link href={{ pathname: item.href }}>
                                <a
                                    className={`${classes.aLink} aLink fnt16rs`}
                                    onClick={handleToggle}
                                    id={item.id}
                                >
                                    {item.label}
                                </a>
                            </Link>
                        )
                    ) : anchor ? (
                        <>
                            <a className={`${classes.aLink} aLink fnt16rs`} href={item.href}>
                                <span className={classes._idle}>{item.label}</span>
                            </a>
                            <span className={classes._hover}>{item.label}</span>
                        </>
                    ) : (
                        <>
                            <Link href={{ pathname: item.href }}>
                                <a
                                    className={`${classes.aLink} aLink fnt16rs`}
                                    onClick={handleToggle}
                                    id={item.id}
                                >
                                    <span className={classes._idle}>{item.label}</span>
                                </a>
                            </Link>
                            <span className={classes._hover}>{item.label}</span>
                        </>
                    )}
                </>
            )}
        </span>
    );
};

export default MenuItem;
