import classes from './DefaultLayout.module.scss';
import Head from 'next/head';
import Navbar from '../organisms/navbar/Navbar';
import Footer from '../organisms/footer/Footer';
import attributes from '../../pages/api/localData/DefaultLayout.json';

/**
 * Default layout
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DefaultLayout = (props) => {
    const { children } = props;

    return (
        <>
            <Head>
                <title>Simfy Africa</title>
                <meta
                    name="description"
                    content="Official Simfy Africa website for company information, jobs and contact form for queries"
                />
            </Head>
            <main role="main" className={`${classes.main} main`}>
                <Navbar {...attributes} />
                {children}
                <Footer {...attributes} />
            </main>
        </>
    );
};

export default DefaultLayout;
