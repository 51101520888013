import classes from './Navbar.module.scss';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import BurgerMenu from '../../molecules/burgerMenu/BurgerMenu';
import MenuItem from '../../molecules/menuItem/MenuItem';
import { isMobile } from 'react-device-detect';
import SocialMedia from '../socialMedia/SocialMedia';

/**
 * Dynamic Navbar
 *
 * @param attributes
 * @returns {JSX.Element|null}
 * @constructor
 */
const Navbar = ({ attributes }) => {
    const router = useRouter();
    const [scrollY, setScrollY] = useState(0);
    const [isActive, setIsActive] = useState(false);
    // const [dropdown, setDropdown] = useState(false);

    const handleToggle = () => setIsActive(!isActive);
    const handleScroll = () => setScrollY(window.scrollY);

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [router]);

    /**
     * return null needed for react render func
     */
    if (!attributes) return null;

    const { logo, menu_options } = attributes;

    /**
     * Custom logic before Strapi update
     */

    return (
        <div
            className={`${classes.oHeader} oHeader ${
                scrollY > 100 ? `${classes.fixed}` : `${classes.top}`
            }`}
        >
            <div className={`${classes.oContainer} container-fluid`}>
                <div
                    className={`${classes.oHeaderWrapper} oHeaderWrapper ${
                        isActive ? `${classes.isActive}` : `${classes.notActive}`
                    }`}
                >
                    <span onClick={handleToggle} className={classes.mBurgerWrapper}>
                        <BurgerMenu />
                    </span>
                    <figure className={`${classes.mSiteLogo} mSiteLogo`}>
                        <Link href="/home">
                            <a className={classes.mLogoLink}>
                                <Image
                                    src={logo.image?.data?.attributes?.url}
                                    alt={`ayoba-logo`}
                                    width={`113.44`}
                                    height={`42.39`}
                                    layout="responsive"
                                    id={logo.image?.data?.attributes?.id}
                                    priority="true"
                                />
                            </a>
                        </Link>
                    </figure>
                </div>
                <nav
                    className={`${classes.oMainNav} oMainNav ${
                        isActive ? `${classes.navOpen}` : `${classes.navClosed}`
                    }`}
                >
                    <ul className={classes.oNavList}>
                        {menu_options?.map((item, index) => {
                            return (
                                <li className={classes.menuItemWrapper} key={index}>
                                    <MenuItem
                                        handleToggle={handleToggle}
                                        item={item}
                                        router={router}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    {isMobile ? (
                        <>
                            <SocialMedia attributes={attributes} themeName="themeLight" />
                            <figure>
                                <svg
                                    width="94"
                                    height="35"
                                    viewBox="0 0 94 35"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_981_22631)">
                                        <path
                                            d="M44.2646 9.30421C43.8113 8.54869 42.5652 7.8325 41.1307 7.8325C39.2678 7.8325 38.3922 8.60665 38.3922 9.58779C38.3922 10.7407 39.7501 11.0616 41.3377 11.2437C44.0948 11.5832 46.6636 12.3014 46.6636 15.456C46.6636 18.4014 44.0576 19.6661 41.11 19.6661C38.4191 19.6661 36.3327 18.8382 35.3516 16.4185L37.4215 15.3421C38.0072 16.791 39.5369 17.4389 41.1473 17.4389C42.7142 17.4389 44.188 16.8904 44.188 15.456C44.188 14.214 42.8839 13.6986 41.1287 13.5103C38.4274 13.1894 35.9353 12.4753 35.9353 9.50706C35.9353 6.78721 38.6158 5.6736 41.0521 5.65497C43.1116 5.65497 45.2436 6.23868 46.2455 8.2796L44.2646 9.30421Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M48.6094 19.3329V6.1145H51.0767V19.3329H48.6094Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M64.9458 9.75752L60.6383 15.4994H60.1478L55.9376 9.73889V19.3515H53.4434V6.13312H56.3143L60.4541 11.8171L64.5939 6.13312H67.4297V19.3515H64.9458V9.75752Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M69.7783 19.3329V6.13312H79.2792V8.53007H72.2726V12.1379H78.8963V14.4459H72.2726V19.337L69.7783 19.3329Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M87.1529 11.5708L90.6718 6.1145H93.6752V6.22628L88.4052 13.8746V19.3329H85.9213V13.8746L80.8418 6.22628V6.1145H83.8059L87.1529 11.5708Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M40.1067 28.0886H37.3869L36.9419 29.2436H35.8779L38.2128 23.127H39.2788L41.6116 29.2436H40.5414L40.1067 28.0886ZM38.7468 24.4104L37.7802 27.0143H39.7135L38.7468 24.4104Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M47.9248 29.2436V23.1395H51.6631V24.2489H48.9059V25.9173H51.514V26.9833H48.9059V29.2478L47.9248 29.2436Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M62.8976 29.2436H61.7302L60.2151 27.1985H59.2712V29.2436H58.29V23.1188C59.118 23.1188 59.946 23.1188 60.7636 23.1188C61.991 23.1188 62.6368 24.0896 62.6368 25.1452C62.6368 25.9732 62.3098 26.8322 61.3225 27.0681L62.8976 29.1567V29.2436ZM59.2712 24.2013V26.1595H60.7636C61.3845 26.1595 61.6557 25.6689 61.6557 25.1804C61.6557 24.6919 61.3804 24.2013 60.7636 24.2013H59.2712Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M69.251 29.2436V23.127H70.2259V29.2436H69.251Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M81.6763 28.4736C81.435 28.7624 81.1323 28.9938 80.7904 29.1509C80.4485 29.3081 80.0758 29.3871 79.6995 29.3823C77.7683 29.3823 76.9486 27.8195 76.9424 26.2278C76.9362 24.636 77.8201 23.0049 79.6995 23.0049C80.0672 23.0108 80.429 23.0977 80.7592 23.2594C81.0895 23.4211 81.38 23.6537 81.61 23.9405L80.9559 24.6815C80.8003 24.5008 80.6075 24.3558 80.3906 24.2565C80.1738 24.1572 79.938 24.1059 79.6995 24.1061C78.4431 24.1061 77.9008 25.2073 77.9091 26.2278C77.9173 27.2482 78.4141 28.2977 79.6995 28.2977C79.949 28.2919 80.1944 28.2336 80.4198 28.1266C80.6452 28.0196 80.8455 27.8663 81.0077 27.6767L81.6763 28.4736Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M91.6341 28.0886H88.9122L88.4671 29.2436H87.4053L89.7381 23.127H90.8185L93.1513 29.2436H92.0812L91.6341 28.0886ZM90.2721 24.4104L89.3075 27.0143H91.2325L90.2721 24.4104Z"
                                            fill="#262626"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.0143087 20.9764C0.0143087 18.8423 -0.02502 16.7062 0.0267276 14.5742C0.0701956 12.8334 0.720143 12.4898 2.2498 13.3633C5.96045 15.4787 9.65523 17.6114 13.3341 19.7614C14.8555 20.6494 14.8265 21.4835 13.2948 22.3695C9.74424 24.4297 6.1909 26.4844 2.63481 28.5336C0.844341 29.5686 0.0184508 29.0946 0.0101712 27.0164C-0.000178363 25.0024 0.0101712 22.9904 0.0101712 20.9764H0.0143087Z"
                                            fill="#262626"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M26.7723 13.7007C26.7723 15.8347 26.8034 17.9709 26.762 20.1029C26.7268 21.7774 26.0562 22.1728 24.63 21.3573C20.8559 19.2032 17.0935 17.0284 13.3428 14.8329C12.0243 14.0629 12.016 13.1646 13.318 12.4028C17.0024 10.2529 20.6958 8.11953 24.3982 6.00271C25.9278 5.12921 26.7537 5.63426 26.7703 7.41852C26.7889 9.51326 26.7744 11.608 26.7723 13.7007Z"
                                            fill="#262626"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M19.2774 29.7983C19.2774 31.1251 19.2919 32.4519 19.2774 33.7808C19.2588 35.0227 18.8821 35.2856 17.8285 34.6957C15.444 33.3544 13.0726 31.9862 10.7143 30.5911C9.7621 30.0281 9.77244 29.4961 10.7453 28.9227C13.0222 27.5773 15.3239 26.2319 17.6401 24.9444C18.7993 24.2944 19.2505 24.5904 19.265 25.9276C19.2899 27.2234 19.2774 28.5212 19.2774 29.7983Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M13.3341 19.7614C9.65523 17.6114 5.96045 15.4787 2.2498 13.3633C0.720143 12.4898 0.0701956 12.8334 0.0267276 14.5742C-0.02502 16.7062 0.0143087 18.8423 0.0143087 20.9764C0.0143087 22.9904 0.0143087 25.0024 0.0143087 27.0164C0.0143087 29.0863 0.842269 29.5686 2.63894 28.5336C6.19504 26.4817 9.74837 24.4269 13.2989 22.3694C14.8265 21.4835 14.8534 20.6556 13.3341 19.7614Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M26.7742 13.7007C26.7742 11.608 26.7887 9.51326 26.7742 7.41852C26.7576 5.63426 25.9317 5.12921 24.4021 6.00271C20.6997 8.11677 17.0063 10.2501 13.3219 12.4028C12.0199 13.1646 12.0282 14.0588 13.3467 14.8329C17.0988 17.0243 20.8611 19.199 24.6339 21.3573C26.0601 22.1707 26.7307 21.7712 26.7659 20.1029C26.8031 17.9647 26.77 15.8347 26.7742 13.7007Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M19.2728 25.9359C19.2583 24.5987 18.8071 24.3027 17.648 24.9527C15.3317 26.2443 13.0383 27.5856 10.7531 28.9352C9.78026 29.5085 9.76991 30.0405 10.7221 30.6035C13.0776 31.9904 15.4463 33.3558 17.828 34.6999C18.8754 35.2898 19.2521 35.0352 19.277 33.785C19.2977 32.4561 19.277 31.1293 19.277 29.8025C19.277 28.5212 19.2894 27.2234 19.2728 25.9359Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M15.9716 5.5163L15.9219 5.55563L15.9716 5.5163Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M7.0957 4.92429C7.0957 4.7587 7.0957 4.59104 7.0957 4.42545C7.09777 4.59932 7.0957 4.76698 7.0957 4.92429Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M7.09726 5.35069C7.09726 5.212 7.09726 5.07332 7.09726 4.93671C7.09519 5.07332 7.09519 5.212 7.09726 5.35069Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M7.11238 1.15916C7.10134 2.24931 7.09651 3.34084 7.09789 4.43375C7.09789 3.26219 7.10616 2.08924 7.12272 0.914917C7.11858 0.991503 7.11445 1.0743 7.11238 1.15916Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M15.9717 5.51629C16.2449 5.3093 16.3857 5.12302 16.3732 4.93259C16.3691 5.1313 16.2346 5.31344 15.9717 5.51629Z"
                                            fill="#262626"
                                        />
                                        <path
                                            d="M15.6029 4.13774C13.315 2.84612 11.0167 1.57243 8.70804 0.316692C7.67309 -0.246322 7.19494 -0.0703769 7.12249 0.921107C7.10731 2.09267 7.09904 3.26562 7.09766 4.43994C7.09766 4.60554 7.09766 4.7732 7.09766 4.93879C7.09766 5.10438 7.09766 5.21823 7.09766 5.35277C7.09766 6.5271 7.10249 7.70004 7.11215 8.87161C7.11215 9.51742 7.24669 9.90656 7.52613 10.0287C7.55638 10.0441 7.58833 10.0559 7.62134 10.0639C7.87594 10.128 8.24231 10.0225 8.71218 9.75132C11.0222 8.44728 13.3198 7.11219 15.607 5.76468C15.7229 5.69637 15.8264 5.62807 15.9154 5.55769H15.9258L15.9755 5.51836C16.2384 5.31137 16.3729 5.12508 16.377 4.93465C16.3667 4.68005 16.11 4.42546 15.6029 4.13774Z"
                                            fill="#262626"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_981_22631">
                                            <rect width="93.6693" height="35" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </figure>
                        </>
                    ) : null}

                    <span onClick={handleToggle} className={classes.mNavBurger}>
                        <BurgerMenu handleToggle={handleToggle} isActive={isActive} />
                    </span>
                </nav>
                <div></div>

                <style global jsx>{`
                    .oSubNav .mNavItem:after {
                        transform: translateX(0);
                        right: 0;
                        left: auto;
                        bottom: 3px;
                    }
                    .oSubNav .mNavItem {
                        margin-top: 18px;
                    }
                    .oSubNav .mNavItem:first-child {
                        margin-top: 23px;
                    }
                    .oSubNav .mNavItem:last-child {
                        margin-bottom: 23px;
                    }
                    .oSubNav .mNavItem .aLink {
                        text-align: right;
                        padding: 0 0 10px 0;
                    }
                `}</style>
            </div>
        </div>
    );
};

export default Navbar;
